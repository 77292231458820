import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SignUp from '../components/SignUp';

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;

`;

export default function SignupPage() {
  return (
    <Layout>
      <FormWrapper>
        <SignUp/>
      </FormWrapper>
    </Layout>
  )
}