import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { navigate } from 'gatsby';

import Form from './styles/Form';
import useForm from '../lib/useForm';
import ErrorMessage from './ErrorMessage';

// TODO: App ID may need to be dynamic? 
const SIGN_UP_MUTATION = gql`
  mutation signUp($firstname: String!, $lastname: String!, $email: String!, $device: String!) {
    insert_betatesters_user(objects: {
      first_name: $firstname,
      last_name: $lastname,
      email: $email,
      device_type: $device,
      app_id: "f03abbee-e9e2-4f54-abec-22d94a9a0f7c"
    }) {
      affected_rows
    }
  }
`;

export default function SignUp() {

  const [signUp, { loading, error }] = useMutation(SIGN_UP_MUTATION, {
    onCompleted: () => {
      // TODO: Check for error 
      navigate('/thankyou/');
      clearForm();
    },
    onError: (error) => {
      console.log(error.message);
      if (error.message.includes("Uniqueness violation")) {
        setUserError({message: "it looks like this email has already been used"});
      } else {
        setUserError({message: "An unexpected error occurred, please try again"});
      }
    }
  });

  const [userError, setUserError] = useState();

  const { inputs, handleChange, clearForm } = useForm({
    firstname: '',
    lastname: '',
    email: '',
    device: ''
  });

  function handleSubmit(event) {
    event.preventDefault();
    signUp({ variables: { ...inputs } })
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ErrorMessage error={userError} />
      <fieldset disabled={loading} aria-busy={loading}>
        <label htmlFor="email">
          Email *
          <input
            required
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={inputs.email}
            onChange={handleChange}
          />
        </label>
        <label htmlFor="firstname">
          First name *
          <input
            required
            type="text"
            id="firstname"
            name="firstname"
            placeholder="First name"
            value={inputs.firstname}
            onChange={handleChange}
          />
        </label>
        <label htmlFor="name">
          Last name *
          <input
            required
            type="text"
            id="lastname"
            name="lastname"
            placeholder="Last name"
            value={inputs.lastname}
            onChange={handleChange}
          />
        </label>
          <label>Which device do you use? *</label>
          <label htmlFor="ios">
            <input required id="ios" type="radio" value="iOS" name="device" onChange={handleChange}/>
            iOS
          </label>
          <label htmlFor="android">  
            <input required id="android" type="radio" value="Android" name="device" onChange={handleChange}/>
            Android
          </label>
        <button type="submit">Sign Up</button>
      </fieldset>
    </Form>
  )
}